// routes
// components
import SvgIconStyle from '../../../components/SvgIconStyle';
import { IconifyIcon } from './exhibitorNavConfig';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const APP_ICONS = {
  exhibitors: getIcon('ic_invoice'),
  add_exhibitor: getIcon('ic_user'),
  ic_chat: getIcon('ic_chat'),
  ic_calendar: getIcon('ic_calendar'),
  ic_analytics: getIcon('ic_analytics'),
  ic_booking: getIcon('ic_booking'),
  ic_banking: getIcon('ic_banking'),
  dashboard: getIcon('ic_dashboard'),
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
};

const navConfig = [
  {
    subheader: 'Dashboard',
    package: 'all',
    accessLevels: [1, 2],
    items: [
      {
        package: 'all',
        title: 'Event Dashboard',
        path: '/admin/dashboard',
        icon: IconifyIcon('dashicons:dashboard'),
        accessLevels: [1, 2],
      },
    ],
  },

  {
    subheader: 'exhibitors & Sponsors',
    package: 'exhibitions',
    accessLevels: [1, 2, 3, 4],
    items: [
      {
        title: 'Onboard exhibitor',
        path: '/admin/exhibitors/add',
        icon: IconifyIcon('ri:user-star-fill'),
        accessLevels: [1, 2],
        package: 'exhibitions',
      },
      {
        title: 'Manage exhibitors',

        path: '/admin/exhibitors/view-exhibitors',
        icon: IconifyIcon('ci:list-checklist-alt'),
        package: 'exhibitions',
        accessLevels: [1, 2, 3, 4],
      },
      {
        title: 'Live Activities',
        path: '/admin/live-activities',
        icon: IconifyIcon('mdi:crown-circle'),
        package: 'exhibitions',
        accessLevels: [1, 2],
      },
      {
        title: 'Manage sponsors',
        package: 'exhibitions',
        path: '/admin/sponsors',
        icon: IconifyIcon('ci:list-checklist-alt'),
        accessLevels: [1, 2, 3, 4],
      },
    ],
  },

  {
    subheader: 'Speakers',
    package: 'registration',
    accessLevels: [1, 2, 3, 4],
    items: [
      {
        title: 'Manage speakers',
        package: 'registration',
        path: '/admin/analytics/speakers',
        icon: IconifyIcon('raphael:users'),
        accessLevels: [1, 2, 3, 4],
      },
    ],
  },

  {
    subheader: 'Sessions',
    package: 'sessions',
    accessLevels: [1, 2],
    items: [
      {
        title: 'Create Event Sessions',
        path: '/admin/sessions/add',
        package: 'sessions',
        icon: IconifyIcon('dashicons:playlist-video'),
        accessLevels: [1, 2],
      },
      {
        title: 'View Event Sessions',
        path: '/admin/sessions/view',
        package: 'sessions',
        icon: IconifyIcon('ci:list-checklist-alt'),
        accessLevels: [1, 2],
      },
      {
        title: 'Modify Event Sessions',
        path: '/admin/sessions/modify',
        package: 'sessions',
        icon: IconifyIcon('fluent:desktop-edit-20-regular'),
        accessLevels: [1, 2],
      },
    ],
  },

  {
    subheader: 'delegate settings',
    package: 'registration',
    accessLevels: [1, 2, 3, 4, 5],
    items: [
      {
        title: 'manage delegate types',
        package: 'registration',
        path: '/admin/delegate-settings/delegate-types',
        icon: IconifyIcon('fa6-solid:users-viewfinder'),
        accessLevels: [1, 2, 3],
      },
      {
        title: 'privileged delegates',
        path: '/admin/delegate-settings/privileged-delegates',
        package: 'registration',
        icon: IconifyIcon('carbon:user-military'),
        accessLevels: [1, 2, 3],
      },
      {
        title: 'delegate codes',
        path: '/admin/delegate-settings/delegate-codes',
        package: 'registration',
        icon: IconifyIcon('bxs:user-account'),
        accessLevels: [1, 2, 3],
      },
      {
        title: 'add delegate',
        path: '/admin/delegate-settings/add-delegate',
        package: 'registration',
        icon: IconifyIcon('mdi:user-add'),
        accessLevels: [1, 2, 3, 4, 5],
      },
    ],
  },

  {
    subheader: 'Event QR',
    package: 'arsh_qr',
    accessLevels: [1, 2, 3, 4],
    items: [
      {
        title: 'Generate Event Tags',
        path: '/admin/generate-tags',
        icon: IconifyIcon('clarity:id-badge-outline-badged'),
        package: 'arsh_qr',
        accessLevels: [1, 2, 3],
      },
      {
        title: 'View QR Scans',
        path: '/admin/qr-scans',
        icon: IconifyIcon('fluent:qr-code-28-regular'),
        package: 'arsh_qr',
        accessLevels: [1],
      },
    ],
  },

  {
    subheader: 'Data & Analytics',
    accessLevels: [1, 2, 3, 4],
    package: 'all',
    items: [
      {
        title: 'Event Analytics',
        path: '/admin/analytics',
        icon: IconifyIcon('ant-design:area-chart-outlined'),
        package: 'all',
        accessLevels: [1, 2, 3, 4],
        children: [
          {
            title: 'Registrants',
            path: '/admin/analytics/registrants',
            package: 'registration',
            icon: APP_ICONS.add_exhibitor,
            accessLevels: [1, 2, 3, 4],
          },
          {
            title: 'Participants',
            path: '/admin/analytics/participants',
            package: 'registration',
            icon: APP_ICONS.add_exhibitor,
            accessLevels: [1, 2, 3, 4],
          },
          {
            title: 'Speakers',
            path: '/admin/analytics/speakers',
            package: 'registration',
            icon: APP_ICONS.add_exhibitor,
            accessLevels: [1, 2, 3, 4],
          },
          {
            title: 'Booths / Exhibitors',
            path: '/admin/exhibitors/view-exhibitors',
            icon: APP_ICONS.add_exhibitor,
            package: 'exhibitions',
            accessLevels: [1, 2, 3, 4],
          },
          {
            title: 'Voting',
            path: '/admin/live-activities',
            icon: APP_ICONS.add_exhibitor,
            package: 'exhibitions',
            accessLevels: [1, 2, 3, 4],
          },
          {
            title: 'Traffic Control',
            path: '/admin/analytics/traffic-control',
            icon: APP_ICONS.add_exhibitor,
            package: 'arsh_qr_security',
            accessLevels: [1],
          },
        ],
      },
    ],
  },

  {
    subheader: 'Event settings',
    accessLevels: [1, 2],
    package: 'all',

    items: [
      {
        title: 'Event settings',
        accessLevels: [1, 2],
        path: '/admin/event-settings',
        package: 'all',
        icon: IconifyIcon('fluent:content-settings-24-regular'),
        children: [
          { title: 'Primary Settings', path: '/admin/event-settings/defaults', accessLevels: [1], package: 'all' },
          {
            title: 'Email Content Settings',
            path: '/admin/event-settings/email',
            accessLevels: [1, 2],
            package: 'registration',
          },
          { title: 'Program of Events', path: '/admin/event-settings/program', accessLevels: [1, 2], package: 'all' },
          {
            title: 'Registration Packages',
            path: '/admin/event-settings/packages',
            accessLevels: [1],
            package: 'registration',
          },
          {
            title: 'Registration Fields',
            path: '/admin/event-settings/reg-fields',
            accessLevels: [1, 2],
            package: 'registration',
          },
        ],
      },
      // { title: 'Music', path: '/admin/music', icon: APP_ICONS.add_exhibitor },
      {
        title: 'Announcements',
        path: '/admin/announcements',
        icon: IconifyIcon('carbon:bullhorn'),
        accessLevels: [1, 2, 3, 4],
        package: 'announcements',
      },
      //This would probably be the same as event settings since its one db.. idk, maybe not.
      // { title: 'Event controls', path: '/admin/event-controls', icon: APP_ICONS.add_exhibitor },
    ],
  },

  {
    subheader: 'administration',
    accessLevels: [1],
    package: 'all',

    items: [
      {
        title: 'Manage Administrators',
        path: '/admin/administrators',
        package: 'all',
        icon: IconifyIcon('clarity:administrator-solid'),
        accessLevels: [1],
      },
      {
        title: 'Activity Log',
        path: '/admin/activity',
        package: 'all',
        icon: IconifyIcon('bi:list-check'),
        accessLevels: [1],
      },
    ],
  },

  {
    subheader: 'Event Live Chat',
    accessLevels: [1, 2, 5],
    package: 'live_chat',
    items: [
      {
        title: 'Live Chat',
        path: '/admin/live-chat',
        package: 'live_chat',
        icon: IconifyIcon('bi:chat-right-dots-fill'),
        accessLevels: [1, 2, 5],
      },
    ],
  },
];

export default navConfig;
