import { m } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Button, Typography, Container } from '@mui/material';
// components
import Page from '../../components/Page';
import { MotionContainer, varFade } from '../../components/animate';
// assets
import { PageNotFoundIllustration } from '../../assets';
import Logo from 'src/components/Logo';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(7),
  paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

export default function EventExpired() {
  return (
    <Page title="Event not active." sx={{ height: 1 }}>
      <Logo isHome sx={{ mx: 'auto', mt: 4 }} />

      <RootStyle>
        <Container component={MotionContainer}>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <m.div variants={varFade().inUp}>
              <Typography variant="h3" paragraph>
                Event is not active.
              </Typography>
            </m.div>
            <m.div variants={varFade().inUp}>
              <Typography sx={{ color: 'text.secondary' }}>
                The event you are trying to access is no longer active. If you are the owner of the event, login to your
                client dashboard to manage your account and events.
              </Typography>
            </m.div>

            <m.div variants={varFade().inUp}>
              <PageNotFoundIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
            </m.div>

            <m.div variants={varFade().in}>
              <Button to="/" size="large" variant="contained" component={RouterLink}>
                Go to Home
              </Button>
              <Button sx={{ ml: 3 }} to="/client/login" size="large" variant="text" component={RouterLink}>
                Account Login
              </Button>
            </m.div>
          </Box>
        </Container>
      </RootStyle>
    </Page>
  );
}
