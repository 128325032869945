import { PlanFreeIcon, PlanStarterIcon, PlanPremiumIcon } from '../assets';

// ----------------------------------------------------------------------

const LICENSES = ['Standard', 'Standard Plus', 'Extended'];

export const _homePlans = [...Array(3)].map((_, index) => ({
  license: LICENSES[index],
  commons: ['One end products', '12 months updates', '6 months of support'],
  options: ['JavaScript version', 'TypeScript version', 'Design Resources', 'Commercial applications'],
  icons: [
    'https://minimal-assets-api.vercel.app/assets/images/home/ic_sketch.svg',
    'https://minimal-assets-api.vercel.app/assets/images/home/ic_figma.svg',
    'https://minimal-assets-api.vercel.app/assets/images/home/ic_js.svg',
    'https://minimal-assets-api.vercel.app/assets/images/home/ic_ts.svg',
  ],
}));

export const _pricingPlans = [
  {
    id: 1,
    subscription: 'ARSH Sessions',
    icon: <PlanStarterIcon />,
    price: 20,
    quarterly: 50,
    caption: 'Virtual Events',
    level: 2,
    lists: [
      { text: 'Registration Platform', isAvailable: true },
      { text: 'Sessions', isAvailable: true },
      { text: 'VIP Lounge', isAvailable: true },
      { text: 'Live Chat', isAvailable: true },
      { text: 'Help Desk	', isAvailable: true },
      { text: 'Resources Uploads & submission', isAvailable: true },
      { text: 'Integrated payments', isAvailable: true },
      { text: 'Exhibition', isAvailable: false },
      { text: 'Premium Branding', isAvailable: false },
    ],
    labelAction: 'Choose Sessions',
    _description:
      'ARSH sessions combines powerful in-house tools and external integrations with popular streaming, conferencing solutions to power virtual events.',
  },
  {
    id: 2,
    level: 1,
    subscription: 'ARSH Comprehensive',
    icon: <PlanPremiumIcon />,
    price: 50,
    quarterly: 126,
    caption: 'Hybird Events',
    lists: [
      { text: 'Registration Platform', isAvailable: true },
      { text: 'ARSH QR — Exhibition ', isAvailable: true },
      { text: 'ARSH QR Access/Security', isAvailable: true },
      { text: 'Sessions', isAvailable: true },
      { text: 'Exhibition', isAvailable: true },
      { text: 'VIP Lounge', isAvailable: true },
      { text: 'Networking lounge', isAvailable: true },
      { text: 'Live Chat', isAvailable: true },
      { text: 'Premium Branding', isAvailable: true },
      { text: 'Integrated payments', isAvailable: true },
    ],
    labelAction: 'Choose Comprehensive',
    _description:
      "Supercharge your event with the complete event management package. Get live sessions, virtual exhibitions, virtual vip / networking lounges, registrations (with QR codes) and so much more. The event manager's dream.",
  },

  {
    id: 3,
    level: 2,
    subscription: 'ARSH Exhibition',
    icon: <PlanStarterIcon />,
    price: 30,
    quarterly: 76,
    caption: 'Virtual Events',
    lists: [
      { text: 'Registration Platform', isAvailable: true },
      { text: 'Exhibition (Virtual & Hybrid)', isAvailable: true },
      { text: 'Live Chat', isAvailable: true },
      { text: 'ARSH QR — Exhibition ', isAvailable: true },
      { text: 'Networking lounge', isAvailable: true },
      { text: 'Integrated payments', isAvailable: true },
      { text: 'Sessions', isAvailable: false },
      { text: 'VIP Lounge', isAvailable: false },
      { text: 'Premium Branding', isAvailable: false },
    ],
    labelAction: 'choose Exhibitions',
    _description: 'Designed to power virtual exhibitions and digital expos',
  },
  {
    level: 4,
    id: 4,
    subscription: 'ARSH Registrations',
    icon: <PlanFreeIcon />,
    price: 15,
    quarterly: 39,
    caption: 'Free for 100 registrations',
    lists: [
      { text: 'Registration Platform', isAvailable: true },
      { text: 'Registration- Name Tagging', isAvailable: true },
      { text: 'Branding', isAvailable: true },
      { text: 'Free for first 100 registrations', isAvailable: true },
      { text: 'Integrated payments', isAvailable: true },
      { text: 'Sessions', isAvailable: false },
      { text: 'Exhibition', isAvailable: false },
    ],
    labelAction: 'Choose Registrations',
    _description: 'Create extensive registration forms, process registration payments in local and foreign currency.',
  },
  {
    level: 3,
    id: 5,
    subscription: 'ARSH Administrator',
    icon: <PlanFreeIcon />,
    price: 25,
    quarterly: 63,
    caption: 'In-person',
    lists: [
      { text: 'Registration Platform', isAvailable: true },
      { text: 'ARSH QR — Exhibition ', isAvailable: true },
      { text: 'ARSH QR Access/Security', isAvailable: true },
      { text: 'Integrated payments', isAvailable: true },
      { text: 'Sessions', isAvailable: false },
      { text: 'Exhibition', isAvailable: false },
    ],
    _description: 'Manage your events with a comprehensive back-office from ARSH',
    labelAction: 'Choose Administrator',
  },
  {
    id: 6,
    subscription: 'Custom Plan',
    icon: <PlanStarterIcon />,
    price: 'Custom',
    caption: 'Contact Support',
    lists: [],
    description: `Take advantage of ARSH's modular design to build a package curated specifically for your needs.`,
    btnType: 'outlined',
    labelAction: 'Contact us',
  },
];
