// routes
// components
// import SvgIconStyle from '../../../components/SvgIconStyle';
import { IconifyIcon } from './exhibitorNavConfig';

// ----------------------------------------------------------------------

// const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

// const APP_ICONS = {
//   exhibitors: getIcon('ic_invoice'),
//   add_exhibitor: getIcon('ic_user'),
//   ic_chat: getIcon('ic_chat'),
//   ic_calendar: getIcon('ic_calendar'),
//   ic_analytics: getIcon('ic_analytics'),
//   ic_booking: getIcon('ic_booking'),
//   ic_banking: getIcon('ic_banking'),
//   dashboard: getIcon('ic_dashboard'),
//   blog: getIcon('ic_blog'),
//   cart: getIcon('ic_cart'),
//   chat: getIcon('ic_chat'),
//   mail: getIcon('ic_mail'),
//   user: getIcon('ic_user'),
//   kanban: getIcon('ic_kanban'),
//   banking: getIcon('ic_banking'),
//   booking: getIcon('ic_booking'),
//   invoice: getIcon('ic_invoice'),
//   calendar: getIcon('ic_calendar'),
//   ecommerce: getIcon('ic_ecommerce'),
//   analytics: getIcon('ic_analytics'),
// };

const navConfig = [
  {
    subheader: 'Main',
    accessLevels: [1, 2, 3],
    package: 'all',
    items: [
      {
        title: 'Dashboard',
        path: '/client/app/dashboard',
        icon: IconifyIcon('dashicons:dashboard'),
        package: 'all',
        accessLevels: [1, 2, 3, 4, 5],
      },
      {
        title: 'Add a new event',
        path: '/client/app/event/new',
        package: 'all',
        icon: IconifyIcon('majesticons:video-plus'),
        accessLevels: [1, 2],
      },
      {
        title: 'Manage Events',
        path: '/client/app/events',
        package: 'all',
        icon: IconifyIcon('ci:list-checklist-alt'),
        accessLevels: [1, 2, 3],
      },
      {
        title: 'Manage Subscriptions',
        path: '/client/app/subscriptions',
        package: 'all',
        icon: IconifyIcon('mdi:crown-circle'),
        accessLevels: [1, 2, 4],
      },
      {
        title: 'Manage Account',
        path: '/client/app/profile',
        package: 'all',
        icon: IconifyIcon('clarity:administrator-solid'),
        accessLevels: [1, 2, 3],
      },
    ],
  },

  // {
  //   subheader: 'Account',
  //   accessLevels: [1, 5],
  //   items: [
  //     {
  //       title: 'Account Settings',
  //       path: '/admin/administrators',
  //       icon: IconifyIcon('clarity:administrator-solid'),
  //       accessLevels: [1],
  //     },
  //     {
  //       title: 'Manage Administrators',
  //       path: '/admin/delegate-settings/delegate-codes',
  //       icon: IconifyIcon('bxs:user-account'),
  //       accessLevels: [1, 2, 3],
  //     },
  //   ],
  // },
];

export default navConfig;
