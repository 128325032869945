import PropTypes from 'prop-types';
// import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx, isHome, isClient, to = '', showtext = true }) {
  const theme = useTheme();
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  // const PRIMARY_MAIN = theme.palette.primary.main;
  // const PRIMARY_DARK = theme.palette.primary.dark;

  const activeEvent = localStorage.getItem('arsh:activeevent');

  const isLight = theme.palette.mode;

  const logo = (
    <Box sx={{ width: 95, height: 40, ...sx }}>
      <svg width="105" viewBox="0 0 655 288" fill="none" xmlns="https://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_19_10)">
          <circle
            cx="136.364"
            cy="147.16"
            r="124"
            transform="rotate(90 136.364 147.16)"
            fill="url(#paint0_linear_19_10)"
          />
        </g>
        <path
          d="M6.87769 145.378C3.57733 142.078 3.57733 136.727 6.87769 133.427L133.644 6.66016C136.945 3.3598 142.296 3.35979 145.596 6.66015V6.66015C148.896 9.96052 148.896 15.3115 145.596 18.6118L18.8294 145.378C15.529 148.679 10.1781 148.679 6.87769 145.378V145.378Z"
          fill={isLight === 'dark' ? '#161c24' : '#fafafa'}
        />
        <path
          d="M21.3316 195.296C17.7476 191.712 17.7476 185.901 21.3316 182.317L183.011 20.6378C186.595 17.0539 192.405 17.0539 195.989 20.6378V20.6378C199.573 24.2217 199.573 30.0324 195.989 33.6163L34.31 195.296C30.7261 198.88 24.9155 198.88 21.3316 195.296V195.296Z"
          fill={isLight === 'dark' ? '#161c24' : '#fafafa'}
        />
        <path
          d="M46.6944 235.741C43.0577 232.105 43.0577 226.208 46.6944 222.572L220.662 48.6042C224.299 44.9675 230.195 44.9675 233.832 48.6042V48.6042C237.468 52.241 237.468 58.1373 233.832 61.774L59.8642 235.741C56.2275 239.378 50.3312 239.378 46.6944 235.741V235.741Z"
          fill={isLight === 'dark' ? '#161c24' : '#fafafa'}
        />
        <path
          d="M136.99 276.604C133.374 272.989 133.374 267.126 136.99 263.511L263.172 137.329C266.787 133.713 272.649 133.713 276.265 137.329V137.329C279.881 140.945 279.881 146.807 276.265 150.423L150.084 276.604C146.468 280.22 140.606 280.22 136.99 276.604V276.604Z"
          fill={isLight === 'dark' ? '#161c24' : '#fafafa'}
        />
        <path
          d="M81.7498 266.563C78.3552 263.168 78.3552 257.664 81.7498 254.269L251.15 84.8696C254.544 81.4749 260.048 81.4749 263.443 84.8696V84.8696C266.837 88.2642 266.837 93.768 263.443 97.1626L94.0429 266.563C90.6482 269.957 85.1445 269.957 81.7498 266.563V266.563Z"
          fill={isLight === 'dark' ? '#161c24' : '#fafafa'}
        />
        {showtext && (
          <path
            d="M316.435 180H292.645L324.975 94.6H349.375L381.705 180H357.915L352.425 164.75H321.925L316.435 180ZM327.415 148.89H346.935L337.175 120.22L327.415 148.89ZM387.8 94.6H439.04C447.58 94.6 453.762 96.552 457.584 100.456C461.488 104.279 463.44 110.46 463.44 119V129.98C463.44 136.731 462.302 141.977 460.024 145.718C457.828 149.459 454.29 151.94 449.41 153.16L464.66 180H440.87L426.84 154.38H409.76V180H387.8V94.6ZM441.48 119C441.48 114.12 439.04 111.68 434.16 111.68H409.76V137.3H434.16C439.04 137.3 441.48 134.86 441.48 129.98V119ZM473.045 162.31C495.33 163.53 510.418 164.14 518.307 164.14C520.178 164.14 521.642 163.611 522.699 162.554C523.838 161.415 524.407 159.911 524.407 158.04V147.06H496.347C487.807 147.06 481.585 145.149 477.681 141.326C473.858 137.422 471.947 131.2 471.947 122.66V119C471.947 110.46 473.858 104.279 477.681 100.456C481.585 96.552 487.807 94.6 496.347 94.6H539.169V111.68H501.227C496.347 111.68 493.907 114.12 493.907 119V121.44C493.907 126.32 496.347 128.76 501.227 128.76H523.187C531.158 128.76 537.014 130.631 540.755 134.372C544.496 138.113 546.367 143.969 546.367 151.94V158.04C546.367 166.011 544.496 171.867 540.755 175.608C537.014 179.349 531.158 181.22 523.187 181.22C519.039 181.22 514.647 181.139 510.011 180.976L498.787 180.488C490.491 180.081 481.91 179.512 473.045 178.78V162.31ZM631.758 180H609.798V145.23H579.298V180H557.338V94.6H579.298V128.15H609.798V94.6H631.758V180Z"
            fill={isLight === 'light' ? '#1F1F1F' : '#ffffff'}
          />
        )}
        <defs>
          <filter
            id="filter0_d_19_10"
            x="0.36438"
            y="15.1599"
            width="272"
            height="272"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology radius="2" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_19_10" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_19_10" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_19_10" result="shape" />
          </filter>
          <linearGradient
            id="paint0_linear_19_10"
            x1="136.364"
            y1="23.1599"
            x2="136.364"
            y2="271.16"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#1540DB" />
            <stop offset="1" stopColor="#3FE7F2" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  if (isHome) {
    return <Link to={`/${to || ''}`}>{logo}</Link>;
  }

  if (isClient) {
    return <Link to={`/client/app/${to || ''}`}>{logo}</Link>;
  }

  return <Link to={`/${activeEvent}/${to === activeEvent ? '' : to}`}>{logo}</Link>;
}
