import PropTypes from 'prop-types';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// components
import LoadingScreen from '../components/LoadingScreen';
import { adminRoles, decrypt } from 'src/helpers/constants';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized, user, logout } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const activeEvent = localStorage.getItem('arsh:activeevent');

  if (!isAuthenticated && pathname.includes('client/app')) {
    return <Navigate to={`/client/login`} />;
  }

  if (!isAuthenticated) {
    if (activeEvent) {
      return <Navigate to={`/${activeEvent}/login?returnURL=${window.location.href}`} />;
    } else {
      const urlPath = window.location.pathname;
      const segments = urlPath.split('/');
      const extractedWord = segments[1];

      return <Navigate to={`/${extractedWord}/login?returnURL=${window.location.href}`} />;
    }
  }

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Navigate to={`/${activeEvent}/login?returnURL=${window.location.href}`} />;
  } else if (isAuthenticated && pathname.includes('returned=true')) {
    const returnURL = pathname.split('return=/')[0];
    console.log(returnURL, pathname);
    return <Navigate to={returnURL} />;
  } else if (isAuthenticated && user?.userType === 'exhibitor' && pathname.includes('/admin')) {
    return <Navigate to={`/exhibitor-dashboard/home`} />;
  } else if (isAuthenticated && user?.userType === 'delegate' && pathname.includes('/admin')) {
    return <Navigate to={`/${activeEvent}/event`} />;
  } else if (isAuthenticated && user?.userType === 'client') {
    return <Navigate to={`/client/app`} />;
  } else if (isAuthenticated && user?.role && user?.userType === 'admin' && pathname.includes('dashboard')) {
    const roleId = adminRoles.filter((role) => role.title === user?.role)[0]?.id;
    if (roleId > 2) {
      return <Navigate to={`/admin/exhibitors/view-exhibitors`} />;
    }
    return <>{children}</>;
  }
  // else if (isAuthenticated && loggedInUserEvent !== activeEvent) {
  //   alert('You have been logged out of ' + loggedInUserEvent + ' so as to visit ' + activeEvent);
  //   logout();
  //   return <Navigate to={`/${activeEvent}`} />;
  // }
  else {
    return <>{children}</>;
  }
  // else if (requestedLocation && pathname !== requestedLocation) {
  //   setRequestedLocation(null);
  //   return <Navigate to={requestedLocation} />;
  // }
}
