import PropTypes from 'prop-types';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar } from '@mui/material';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER, NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
// import Iconify from '../../../components/Iconify';
// import { IconButtonAnimate } from '../../../components/animate';
//
// import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
// import LanguagePopover from './LanguagePopover';
import ContactsPopover from './ContactsPopover';
import NotificationsPopover from './NotificationsPopover';
import { Link } from 'react-router-dom';
import { ChatBox } from 'src/pages/Event/EventContainer';
import { useState } from 'react';
import LanguagePopover from 'src/layouts/dashboard/header/LanguagePopover';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  paddingTop: 23,
  paddingBottom: 15,
  height: 'auto',
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: 'auto',
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: 'auto',
    }),
    ...(verticalLayout && {
      width: '100%',
      height: 'auto',
    }),
  },
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
};

export default function DashboardHeader({ onOpenSidebar, isCollapse = false, verticalLayout = false }) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const [ShowChat, setShowChat] = useState(false);

  const isDesktop = useResponsive('up', 'lg');
  const eventDetails = JSON.parse(localStorage.getItem('arsh:event'));
  const activeEvent = localStorage.getItem('arsh:activeevent');
  const theme = useTheme();

  return (
    <RootStyle isCollapse={false} isOffset={isOffset} verticalLayout={true}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
        }}
      >
        <Link to={`/${activeEvent}/event`}>
          <img
            alt={eventDetails?.title}
            src={eventDetails?.event_image}
            style={{ marginRight: 10, height: 55, marginTop: 0 }}
          />
        </Link>

        {/* {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )} */}
        <Box sx={{ flexGrow: 1 }} />
        {isDesktop && <Logo to="/" />}

        <Stack
          direction="row"
          style={{
            paddingLeft: 10,
            marginLeft: 30,
            borderLeft: isDesktop ? '1px solid ' + theme.palette.action.selected : 'none',
          }}
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          <LanguagePopover />
          {/* <NotificationsPopover /> */}
          <ContactsPopover setShowChat={setShowChat} />
          <AccountPopover />
        </Stack>
      </Toolbar>
      {ShowChat && <ChatBox setShowChat={setShowChat} activeEvent={activeEvent} />}
    </RootStyle>
  );
}
