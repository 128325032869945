import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { List, Box, ListSubheader } from '@mui/material';
//
import { NavListRoot } from './NavList';
import useAuth from 'src/hooks/useAuth';
import { adminRoles } from 'src/helpers/constants';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  })
);

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function NavSectionVertical({ type, navConfig, isCollapse = false, ...other }) {
  const { user } = useAuth();
  const eventDetails = JSON.parse(localStorage.getItem('arsh:event'));

  const clientFeatures = !type ? 'all' : eventDetails?.clientFeatures || '';

  const roleInformation =
    type === 'exhibitor' || !type ? { id: 1 } : adminRoles.filter((item) => item.title === user?.role)[0];

  return (
    <Box {...other}>
      {navConfig.map(
        (group) =>
          group?.accessLevels.includes(roleInformation?.id) &&
          clientFeatures?.includes(group?.package) && (
            <List key={group.subheader} disablePadding sx={{ px: 2 }}>
              <ListSubheaderStyle
                sx={{
                  ...(isCollapse && {
                    opacity: 0,
                  }),
                }}
              >
                {group.subheader}
              </ListSubheaderStyle>

              {group.items.map((list) => (
                <NavListRoot type={type} key={list.title} list={list} isCollapse={isCollapse} />
              ))}
            </List>
          )
      )}
    </Box>
  );
}
