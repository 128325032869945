import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import axios from '../api/axiosInstance';
import { setSession } from '../utils/jwt';
import { endpoints } from '../api/endpoints';
import { Navigate } from 'react-router';
import { post } from 'src/api/requests';
import { toast } from 'react-toastify';
const queryString = require('query-string');

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      isInitialized: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  client_login: () => Promise.resolve(),
  silent_login: () => Promise.resolve(),
  update: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      const user = localStorage.getItem('arsh:user');
      if (!user) {
        return <Navigate to="/" />;
      } else {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user: JSON.parse(user),
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password, show_joining_information, activeEvent) => {
    const response = await axios.post(endpoints?.login, {
      email,
      password,
      show_joining_information,
      event: activeEvent,
    });
    const { accessToken, user } = response.data;

    const isApp = window.location.search.includes('app') && window.location.search.includes('code');

    const appSuccessFn = () => {
      const resolveAfter3Sec = new Promise((resolve) => setTimeout(resolve, 8000));
      toast.promise(resolveAfter3Sec, {
        pending: 'Please wait, you are being redirected....',
        success: 'Login successful...',
        error: 'Please wait..',
      });
    };

    if (isApp) {
      appSuccessFn();

      const { code } = queryString.parse(window.location.search);
      post(
        endpoints.update893,
        { code, email, event: activeEvent },
        () => appSuccessFn(),
        () => {
          console.log('failed to notify app');
        },
        true
      );
    } else {
      const hasRedirect = window.location.search.includes("returnURL=")
      const { returnURL } = queryString.parse(window.location.search);

      setSession(accessToken, user);

      dispatch({
        type: 'LOGIN',
        payload: {
          user,
        },
      });

      if(hasRedirect){
        window.location.href = returnURL        
      }

    }
  };

  const silent_login = async (event) => {
    const response = await axios.post(endpoints?.client_silent_login, {
      event,
    });
    const { accessToken, user } = response.data.data;

    logout();

    setSession(accessToken, user);
    localStorage.setItem('arsh:activeevent', event);

    window.location.href = '/admin';

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const client_login = async (email, password) => {
    const response = await axios.post(endpoints?.client_login, {
      email,
      password,
    });
    const { accessToken, user } = response.data;

    setSession(accessToken, user);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const update = async (user) => {
    setSession('ewko0i', user);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });

    setTimeout(() => {
      window.location.href = '';
    }, 5000);
  };

  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        silent_login,
        client_login,
        update,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
