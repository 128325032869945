import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, CircularProgress } from '@mui/material';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR } from '../../config';
//
import DashboardHeader from './header';
import { endpoints } from '../../api/endpoints';
import { get } from '../../api/requests';
import useSettings from 'src/hooks/useSettings';

// ----------------------------------------------------------------------

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ----------------------------------------------------------------------

export default function EventLayout() {
  const { collapseClick, isCollapse } = useCollapseDrawer();

  const [setOpen] = useState(false);

  const [stillLoading, setstillLoading] = useState(true);

  const { onChangeColorCustom } = useSettings();

  const successFn = (data) => {
    localStorage.setItem('arsh:event', JSON.stringify(data?.rows[0]));
    if (data?.rows[0]?.ann) {
      console.log('yes announcements');
      localStorage.getItem('arsh:announcements', JSON.stringify(data?.rows[0]?.ann));
    } else {
      console.log('no announcements');
    }
    setTimeout(() => {
      setstillLoading(false);
    }, 1000);
    setTimeout(() => {
      document.getElementById('re3423x').click();
    }, 2000);
  };

  const errorFn = (error) => {
    window.location.href = '../../';
  };

  useEffect(() => {
    const client = window.location.pathname.split('/');
    const activeEvent = client[1];
    get(`${endpoints?.geteventdetails}?event=${activeEvent}`, successFn, errorFn);
  }, []);

  const ChangeToClientColor = () => {
    const color = JSON.parse(localStorage.getItem('arsh:event'))?.color;
    onChangeColorCustom(color || 'blue');
  };

  return stillLoading ? (
    <>
      <p id="re3423x" onClick={() => ChangeToClientColor()}></p>
      <CircularProgress sx={{ margin: '100px auto', display: 'block' }} />
    </>
  ) : (
    <>
      <p id="re3423x" onClick={() => ChangeToClientColor()}></p>

      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
        }}
      >
        <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />
        <MainStyle collapseClick={collapseClick}>
          <Outlet />
        </MainStyle>
      </Box>
      {/* <Container>
        <Logo sx={{ mb: 1, mx: 'auto' }} />

        <Typography sx={{ textAlign: 'center' }} variant="caption" component="p">
          Powered by{' '}
          <Link target="_blank" rel="noreferrer" href="https://arsh.events/">
            ARSH
          </Link>
        </Typography>
      </Container> */}
    </>
  );
}
