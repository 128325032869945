export const endpoints = {
  geteventdetails: 'api/v1/geteventdetails',
  geteventpackages: 'api/v1/geteventpackages',
  checkuseremail: 'api/v1/checkuseremail',
  validateeventcodes: 'api/v1/validateeventcodes',
  registergrouppurchase: 'api/v1/registergrouppurchase',
  getallusers: 'api/v1/getallusers',
  register_speaker: 'api/v1/register_speaker',
  register: 'api/v1/register',
  login: 'api/v1/login',
  client_login: 'api/v1/client_login',
  update_emailcontent: 'api/v1/update_emailcontent',
  edit_event_information: 'api/v1/edit-event-settings',
  upgrade_package: 'api/v1/upgrade_package',
  editeventpackages: 'api/v1/editeventpackages',
  delegateverification: 'api/v1/delegateverification',
  generatecodes: 'api/v1/generatecodes',
  addpriviledgeddelegate: 'api/v1/addpriviledgeddelegate',
  updatedelegatetypes: 'api/v1/updatedelegatetypes',
  addadmin: 'api/v1/addadmin',
  deleteadmin: 'api/v1/deleteadmin',
  delete: 'api/v1/delete',
  getusercountries: 'api/v1/getusercountries',
  addexhibitor: 'api/v1/addexhibitor',
  editexhibitor: 'api/v1/editexhibitor',
  logadminactivity: 'api/v1/logadminactivity',
  getexhibitordetails: 'api/v1/getexhibitordetails',
  getspeaker: 'api/v1/getspeaker',
  checksessionbalance: 'api/v1/checksessionbalance',
  addsession: 'api/v1/addsession',
  create_session: 'v1/rooms/',
  getsessions: 'api/v1/geteventsessions/',
  updateprogramofevents: 'api/v1/updateprogramofevents/',
  settabletaken: 'api/v1/settabletaken/',
  exittable: 'api/v1/exittable/',
  logvisit: 'api/v1/logvisit',
  getvisitorcountries: 'api/v1/getvisitorcountries',
  getanalyticsexhibitor: 'api/v1/getanalyticsexhibitor',
  getclients: 'api/v1/getclients',
  getexhibitoranalytics: 'api/v1/getexhibitoranalytics',
  getusersbyfilters: 'api/v1/getusersbyfilters',
  logqrscan: 'api/v1/logqrscan',
  getscannedqrs: 'api/v1/getscannedqrs',
  modifysession: 'api/v1/modifysession',
  editadmin: 'api/v1/editadmin',
  getannouncements: 'api/v1/getannouncements',
  addannouncement: 'api/v1/addannouncement',
  getactivity: 'api/v1/getactivity',
  editlivevideoexhibitor: 'api/v1/editlivevideoexhibitor',
  markattendance: 'api/v1/markattendance',
  addsponsorship: 'api/v1/addsponsorship',
  savevote: 'api/v1/logvote',
  adminregisteruser: 'api/v1/adminregisteruser',
  contact: 'api/v1/contact',
  addregfield: 'api/v1/addregfield',
  editregfield: 'api/v1/editregfield',
  addclient: 'api/v1/addclient',
  getsubscriptions: 'api/v1/get_client_subscriptions',
  getclientevents: 'api/v1/get_client_events',
  initiatesubscription: 'api/v1/initiatesubscription',
  getclientssummary: 'api/v1/getclientssummary',
  client_silent_login: 'api/v1/logactivitydate39',
  addevent: 'api/v1/addevent',
  editclient: 'api/v1/edit-client',
  update893: 'api/v1/893/update',
  getsignature: 'api/v1/getsignature',
  getsponsordetails: 'api/v1/getsponsordetails',
  resetpassword: 'api/v1/resetpassword',
  updateparticipationlink: 'api/v1/updateparticipationlink',
  getuserswithprintedtags: 'api/v1/getuserswithprintedtags',
};
