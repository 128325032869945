import axios from 'axios';
import { serverURL } from '../helpers/constants';

const axiosInstance = axios.create({
  baseURL: serverURL,
  headers: {
    Authorization: `Bearer 0oicdj2908e9uw`,
    'Content-Type': 'application/json',
  },
});

export default axiosInstance;
