export const eventSideBarElements = [
  {
    package: 'exhibitions',
    title: 'Exhibition Hall',
    link: 'exhibitors',
    description: 'View exhibitors and exhibition stands',
    icon: 'bxs:store',
    iconColors: { color: '#40cc99', bg: '#39b78922' },
  },
  {
    package: 'sessions',
    title: 'Live Conference ',
    link: 'live',
    description: 'See & join ongoing live sessions',
    icon: 'ri:live-line',
    iconColors: { color: '#ff1144', bg: '#e50f3d22' },
  },
  {
    package: 'vip_lounge',
    title: 'Vip Lounge',
    link: 'vip',
    description: 'Connect with VIP participants',
    icon: 'ri:vip-crown-fill',
    iconColors: { color: '#9932cc', bg: '#892db722' },
  },
  {
    package: 'networking_lounge',
    title: 'Networking Lounge',
    link: 'networking',
    description: 'Connect with delegates',
    icon: 'ph:users-four-fill',
    iconColors: { color: '#cc7332', bg: '#f7ba8e22' },
  },
  {
    package: 'help_desk',
    title: 'Help Desk',
    link: 'exhibitors/help-desk',
    description: 'Get help via virtual help desk',
    icon: 'iconoir:headset-help',
    iconColors: { color: '#32a4cc', bg: '#50bfe622' },
  },
];
