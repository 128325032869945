// components
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: 'Home',
    icon: <Iconify icon={'material-symbols:home'} {...ICON_SIZE} />,
    path: '/',
  },
  {
    icon: <Iconify icon={'material-symbols:compare-arrows-rounded'} {...ICON_SIZE} />,
    title: 'Compare packages',
    path: '/compare-plans',
  },
  // {
  //   title: 'Compare',
  //   path: '/services',
  // },
  // {
  //   title: 'Enter an event',
  //   path: '/select-event',
  // },
];

export default menuConfig;
