import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Link, Typography } from '@mui/material';
// hooks
import useAuth from '../../../hooks/useAuth';
// routes
// components
import MyAvatar from '../../../components/MyAvatar';
import { decrypt } from 'src/helpers/constants';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavbarAccount.propTypes = {
  isCollapse: PropTypes.bool,
};

export default function NavbarAccount({ type, isCollapse }) {
  const { user } = useAuth();

  return (
    <Link underline="none" color="inherit" component={RouterLink} to="/admin/edit-profile">
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: 'transparent',
          }),
        }}
      >
        {type === 'exhibitor' ? (
          <img style={{ margin: '0 auto' }} alt={user?.name} src={user?.logo} />
        ) : (
          <>
            <MyAvatar />
            <Box
              sx={{
                ml: 2,
                transition: (theme) =>
                  theme.transitions.create('width', {
                    duration: theme.transitions.duration.shorter,
                  }),
                ...(isCollapse && {
                  ml: 0,
                  width: 0,
                }),
              }}
            >
              <Typography variant="subtitle2" noWrap>
                {decrypt(user?.name)}
              </Typography>
              <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
                {user?.role || 'Exhibitor'}
              </Typography>
            </Box>
          </>
        )}
      </RootStyle>
    </Link>
  );
}
