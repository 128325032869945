import { styled, alpha, useTheme } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
import Iconify from '../../../components/Iconify';
import { eventSideBarElements } from 'src/helpers/links';
import { useNavigate } from 'react-router';

const SideBarElement = styled(Card)(({ theme }) => ({
  height: 'auto',
  // minHeight: 500,
  width: 130,
  position: 'fixed',
  right: 30,
  top: 140,
  zIndex: 99,
  padding: theme.spacing(2, 1.5, 1, 1.5),
  backgroundColor: '#13181c',
}));

const SideBarChild = styled(Card)(({ theme }) => ({
  transition: 'all 0.2s ease',
  '&:hover': {
    transform: 'scale(1.1)',
    backgroundColor: alpha(theme.palette.primary.light, 0.16),
    '& div, & span': {
      color: '#fff !important',
    },
  },
}));

export const IconWrapperStyle = styled('div')(({ theme }) => ({
  width: 24,
  height: 24,
  display: 'flex',
  marginBottom: 5,
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.success.main,
  backgroundColor: alpha(theme.palette.success.main, 0.16),
}));

export const IconWrapperStyleReusable = styled('div')(({ theme }) => ({
  width: 24,
  height: 24,
  display: 'flex',
  marginBottom: 5,
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.success.main,
  backgroundColor: alpha(theme.palette.success.main, 0.16),
}));

export const SideBar = ({ page }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const eventDetails = JSON.parse(localStorage.getItem('arsh:event'));

  return (
    <SideBarElement>
      {eventSideBarElements.map(
        (element) =>
          eventDetails?.clientFeatures?.includes(element?.package) && (
            <SideBarChild
              onClick={() => navigate('../' + element?.link)}
              key={element?.title}
              variant={element?.title.toLowerCase().includes(page) ? 'elevation' : ''}
              sx={{
                background: element?.title.toLowerCase().includes(page) ? theme.palette.primary.dark : 'transparent',
                boxShadow: element?.title.toLowerCase().includes(page) ? '0px 0px 10px rgba(0,0,0,.2)' : 'unset',
                mb: 1.5,
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                cursor: 'pointer',
                p: 2,
              }}
            >
              <IconWrapperStyle
                sx={{
                  height: 32,
                  width: 32,
                  color: element?.title.toLowerCase().includes(page)
                    ? theme.palette.sidebarItems.mainText
                    : theme.palette.text.secondary,
                  bgcolor: '#eeeeee11',
                }}
              >
                <Iconify width={19} height={19} icon={element?.icon} />
              </IconWrapperStyle>
              <Typography
                component="span"
                sx={{
                  textAlign: 'center',
                  fontSize: 12,
                  color: element?.title.toLowerCase().includes(page)
                    ? theme.palette.sidebarItems.mainText
                    : theme.palette.text.secondary,
                }}
                variant="subtitle2"
              >
                {element?.title}
              </Typography>
            </SideBarChild>
          )
      )}
    </SideBarElement>
  );
};
