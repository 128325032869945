import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
// components
import { get } from '../api/requests';
import { endpoints } from '../api/endpoints';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import useSettings from '../hooks/useSettings';

// ----------------------------------------------------------------------
import LoadingScreen from '../components/LoadingScreen';
import { toast } from 'react-toastify';
import EventExpired from 'src/pages/Error/EventExpired';
import { checkIfPackageExpired } from 'src/helpers/functions';

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

// ----------------------------------------------------------------------

export default function LogoOnlyLayout({ blocking }) {
  const client = window.location.pathname.split('/');
  const activeEvent = client[1];
  const { onChangeColorCustom } = useSettings();

  const [returnedData, setreturnedData] = useState(null);
  const [error, setError] = useState(false);
  const [expired, setExpired] = useState(false);
  const [stillLoading, setstillLoading] = useState(true);
  const [data] = useState(true);

  const successFn = (data) => {
    localStorage.setItem('arsh:event', JSON.stringify(data?.rows[0]));
    setreturnedData(data?.rows[0]?.eventRows[0]);
    localStorage.setItem('arsh:activeevent', activeEvent);
    setTimeout(() => {
      setstillLoading(false);
    }, 1000);
    if (!window.location.search.includes('app')) {
      setTimeout(() => {
        document.getElementById('re3423').click();
      }, 2000);
    }
  };

  const errorFn = (error) => {
    toast.error(error);
    setError(error);
  };

  useEffect(() => {
    get(`${endpoints?.geteventdetails}?event=${activeEvent}`, successFn, errorFn, false);
  }, [data]);

  const ChangeToClientColor = () => {
    const color = JSON.parse(localStorage.getItem('arsh:event'))?.color;
    console.log(color);
    onChangeColorCustom(color || 'blue');
  };

  useEffect(() => {
    if (returnedData) {
      if (checkIfPackageExpired(returnedData?.client_subscription_ends)?.isExpired === true) {
        setExpired(true);
      }
    }
  }, [returnedData]);
  return (
    <>
      <HeaderStyle>
        <p id="re3423" onClick={() => ChangeToClientColor()}></p>
      </HeaderStyle>
      {error && <Navigate to="/404" />}
      {stillLoading && blocking ? <LoadingScreen /> : expired ? <EventExpired /> : <Outlet />}
    </>
  );
}
