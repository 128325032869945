import { useQuery } from 'react-query';
import axios from './axiosInstance';

// const errorMsg = (msg = 'error') => {
//   alert(msg);
// };
// timeout in seconds
const timeout = 5;

export const fetcher = async (url) =>
  axios
    .get(url, {
      timeout: timeout * 1000,
    })
    .then((response) => response.data);

export const useGetData = (title, url, config = {}) => {
  return useQuery(title, () => fetcher(url), config);
};

export const get = async (url, successFallback, errorFallback, errorAsArr) => {
  await axios
    .get(url, {
      timeout: timeout * 1000,
    })
    .then((response) => {
      successFallback(response?.data);
    })
    .catch((error) => {
      if (error?.response) {
        const errors = error?.response?.data?.errors;
        if (!errorAsArr) {
          for (let i = 0; i < errors?.length; i++) {
            errorFallback(errors[i]?.msg || errors[i]);
          }
        } else {
          errorFallback(errors);
        }
      } else if (error?.request) {
        errorFallback(['Something went wrong in your request']);
      }
    });
};

export const getData = async (url) =>
  axios
    .get(url, {
      timeout: timeout * 1000,
    })
    .then((response) => response?.data);

export const post = async (
  url,
  config,
  onSuccess = () => console.log('api success'),
  onError = () => console.log('api failure'),
  errorAsArr
) => {
  await axios
    .post(url, config)
    .then((response) => {
      onSuccess(response?.data);
    })
    .catch((error) => {
      console.log(error);
      if (error?.response) {
        const errors = error?.response?.data?.errors;
        if (!errorAsArr && errors) {
          for (let i = 0; i < errors?.length; i++) {
            onError(errors[i]?.msg || errors[i]);
          }
        } else {
          onError(errors || error?.message);
        }
      } else if (error?.request) {
        onError(['Something went wrong in your request']);
      } else {
        onError(error?.message);
      }
    });
};
