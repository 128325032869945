// hooks
import { decrypt } from 'src/helpers/constants';
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { user } = useAuth();
  let name = '';
  if (!user?.name && !user?.client_name) {
    name = user?.first_name + ' ' + user?.last_name;
  } else if (user?.client_name) {
    name = user?.client_name;
  } else {
    name = user.name;
  }
  return (
    <Avatar
      src={user?.image}
      alt={decrypt(user?.name) || decrypt(name)}
      color={user?.photoURL ? 'default' : createAvatar(decrypt(user?.name) || decrypt(name)).color}
      {...other}
    >
      {createAvatar(decrypt(user?.name) || decrypt(name)).name}
    </Avatar>
  );
}
